import React from "react";
import girlImage from "../src/assets/Newgirl sitting (1).png";
import dots from "../src/assets/dotspng.png";
import lines from "../src/assets/lines.png";
import backgroundSVG from "../src/assets/firstbg.png";
import { Link } from "react-router-dom";

const Streamlining = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${backgroundSVG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col-reverse md:justify-between lg:flex-row space-y-8 md:space-y-0 md:space-x-8">
          <div className="p-2 md:p-0 md:mt-24 text-white">
        
            <h2 className="md:text-6xl text-3xl font-semibold mb-4 md:w-[42rem] leading-12 md:leading-normal">
              Enhancing Efficiency, Empowering Your Workforce
            </h2>
    
            <p className="md:w-[42rem] md:text-2xl">
              HR Desk supports businesses of all sizes, providing comprehensive
              HR task management.
            </p>
            <div className="flex justify-center md:justify-start w-full md:w-auto my-6">
              <div>
                <Link to="http://app.hrdesk.africa/signin">
                  <button className="mr-2 md:mr-4 bg-[#274980] text-white text-xs px-4 py-2 md:py-[0.6rem] md:px-5 rounded-lg font-semibold hover:bg-white hover:text-black transition duration-300 w-32">
                    Sign In
                  </button>
                </Link>
              </div>
              <div>
                <Link to="http://app.hrdesk.africa/signup">
                  <button className="bg-white text-gray-800 text-xs px-4 py-2 md:py-[0.6rem]  md:px-5 rounded-lg font-semibold hover:bg-gray-600 hover:text-white transition duration-300 w-32">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center align-center">
            <img
              src={girlImage}
              alt="Logo"
              className=" w-[20rem] md:w-[40rem]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Streamlining;
