import React from "react";
import logo from "../src/assets/hrdesk-logo-white.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="text-white">
      <div className="container mx-auto py-4 px-4 flex flex-col md:flex-row items-center justify-between">
        {/* Logo */}
        <div className="flex items-center mb-4 md:mb-0">
          <span className="text-xl font-bold md:mr-2">
            <img src={logo} alt="logo" className="w-40" />
          </span>
        </div>
        {/* Navigation */}
        <div className="hidden md:flex justify-center w-full md:w-auto lg:mt-6">
          <div>
            <Link to="http://app.hrdesk.africa/signin">
              <button className="mr-2 md:mr-4 bg-[#151413] text-white font-semibold text-xs px-4 py-2 md:py-[0.6rem] md:px-5 rounded-lg hover:bg-white hover:text-black transition duration-300 w-32">
                Sign In
              </button>
            </Link>
          </div>
          <div>
            <Link to="http://app.hrdesk.africa/signup">
              <button className="bg-white text-black text-xs font-semibold px-4 py-2 md:py-[0.6rem]  md:px-5 rounded-lg hover:bg-gray-600 hover:text-white transition duration-300 w-32">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
