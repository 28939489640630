import React from 'react'
import LandingPage from "./LandingPage";
import backgroundSVG from "../src/assets/Ellipse 121.png";

const Main = () => {
  return (
    <div className="bg-[#121110] overflow-auto font-sans"
    style={{ backgroundImage:`url(${backgroundSVG})`, 
    backgroundRepeat: "no-repeat", 
    backgroundPosition: "top right"
    }}> 
         <LandingPage />
    </div>  )
}

export default Main