import React from "react";
import dots from "../src/assets/dotspng.png";


const Features = () => {
  return (
    <section className="py-8">
      <div className="container mx-auto px-4">
 
        <h2 className="md:text-5xl text-3xl text-center text-white font-bold mb-10 md:mb-20">
          Key Features
        </h2>
   
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="w-full md:w-auto md:max-w-md md:p-12 p-6 bg-white rounded-lg shadow-md relative">
            <h3 className="text-xl font-semibold mb-4 text-[#274980]">Payroll Processing</h3>
            <p>
            Automate payroll calculations, generate payslips and ensure compliance with tax regulations effortlessly.
            </p>
          </div>
          <div className="md:p-12 p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-[#274980]">Reporting and Analytics</h3>
            <p>
            Gain valuable insights into your HR metrics with customisable reports and analytics dashboards.

            </p>
          </div>
          <div className="md:p-12 p-6 bg-white rounded-lg shadow-md">
       
            <h3 className="text-xl font-semibold mb-4 text-[#274980]">Benefits Administration</h3>
            <p>
            Manage employee benefits seamlessly, including health insurance, retirement plans, and employee perks.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
