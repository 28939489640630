import React from "react";
import { FiFacebook } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import logo from "../src/assets/hrdesk-logo-white.png";

const Footer = () => {
  return (
    <footer className="text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center md:justify-start">
          <span className="text-xl font-bold md:mr-2">
            <img src={logo} alt="logo" className="w-40" />
          </span>
        </div>
        <div className="flex justify-center">
          <nav>
            <ul className="flex items-center space-x-4 mb-2">
              <li>
                <a href="#" className="hover:text-gray-300">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-300">
                  Career
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-300">
                  Help
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-300">
                  Privacy
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flex justify-center md:justify-end">
          <ul className="flex items-center space-x-4 mb-2 md:pb-0">
            <li>
              <a href="#" className="hover:text-gray-300">
                <FiTwitter />
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-300">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-300">
                <BsInstagram />
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-300">
                <FiFacebook />
              </a>
            </li>
          </ul>
        </div>
        <hr></hr>

        <div className="flex justify-center">
          <p className="text-xs py-3">
            {" "}
            hrdesk &copy; 2023. All right reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
