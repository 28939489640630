import React from "react";

const SecondFeatures = () => {
  return (
    <section className="pb-8 md:mt-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:mx-44">
          <div className="p-6 md:p-12 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-[#274980]">Recruitment and Onboarding</h3>
            <p>
            Streamline your recruitment process, from job posting and candidate screening to onboarding new hires smoothly.
            </p>
          </div>
          <div className="p-6 md:p-12 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-[#274980]">Employee Management</h3>
            <p>
            Easily manage employee profiles, and monitor performance all in one place using the OKRs menu customised just for you.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondFeatures;
