import React from "react";
import girlImage from "../src/assets/Girl standing.png";
import chart from "../src/assets/chart.png";
import lock from "../src/assets/Padlockpng.png";
import backgroundSVG from "../src/assets/wiggly.png";


const WhySection = () => {
  return (
    <section className="py-8 md:mt-8"
    style={{
      backgroundImage: `url(${backgroundSVG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl text-center text-white font-bold mb-8">
          Why hrdesk?
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="flex-1">
            <div className="p-6 md:flex text-white">
              <div>
                <img src={girlImage} alt="girl" className="w-[30rem]" />
              </div>
              <div className="mb-2 text-center md:text-left">
                <h2 className="font-bold text-xl mt-6 pb-2">
                  User Friendly Interface
                </h2>
                <p className="text-sm md:w-64 font-light">
                  HR Desk is designed with simplicity, making it easy for HR
                  professionals, employees, and job seekers to navigate and use.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="p-6 md:flex md:flex-row-reverse text-white">
              <div>
                <img src={chart} alt="chart" className="w-[50rem] h-[20rem]" />
              </div>
              <div className="flex  items-center my-4">
                <div className="text-center md:text-left ">
                  <h2 className="font-bold text-xl  pb-2">Scalable Solution</h2>
                  <p className="text-sm">
                    Whether a small team or a large organization, HR Desk scales
                    with your business, adapting to your evolving HR needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <div className="flex-1">
            <div className="md:flex md:flex-row-reverse items-center justify-center text-white">
              <div className="md:w-3/5">
                <img src={lock} alt="lock" className="w-[30rem]" />
              </div>
              <div className="md:text-right text-center mx-2 md:mx-0 md:w-72 mb-2">
                <h2 className="font-bold text-xl pb-2">Secure and Reliable</h2>
                <p className="text-sm">
                  With robust security features and reliable performance, you
                  can trust HR Desk to safeguard your sensitive HR data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
