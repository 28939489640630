import React from "react";
import Header from "./Header";
import Features from "./Features";
import Footer from "./Footer";
import Streamlining from "./Streamlining";
import SecondFeatures from "./SecondFeatures";
import WhySection from "./WhySection";
import backgroundSVG from "../src/assets/secondBg.png";


const LandingPage = () => {
  return (
    <div className="lg:mx-20">
      <Header />
      <Streamlining />
      <div
         style={{
          backgroundImage: `url(${backgroundSVG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Features />
        <SecondFeatures />
      </div>
      <WhySection />
      <Footer />
    </div>
  );
};

export default LandingPage;
